import React from "react";
// import { blog } from "../../../dummydata"
import "./Footer.css";
import "../Nav/Nav.css";
import Footerlogo from "../../assets/img/footerlogo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        {/* <div className="container padding">
          <div className="box logo">
            <div className="img">
              <img src={Logo} alt="" />
            </div>
          </div>
          <div className="box link">
            <h3>Complianc</h3>
            <ul className="w-highlight">
              <li>ADA</li>
              <li>WCAG</li>
            </ul>
            <h3>Soon</h3>
            <ul className="w-highlight">
              <li>Section 508</li>
              <li>EN 301-549</li>
              <li>AODA</li>
              <li>HPAA</li>
              <li>GDPR</li>
              <li>COPPA</li>
              <li>FERPA</li>
              <li>ATAG</li>
              <li>CVAA</li>
            </ul>
          </div>
          <div className="box link">
            <h3>Industries</h3>
            <ul className="w-highlight">
              <li>Banking, Financial, Insurance</li>
              <li>Academia & Education</li>
              <li>Consumer & Retail</li>
              <li>IT & Security</li>
              <li>Healthcare & Medical</li>
              <li>Automotive & Transportation</li>
              <li>Hospitality + F&B</li>
              <li>NGO & NPO</li>
              <li>Media & Entertainment</li>
              <li>Law Enforcement</li>
            </ul>
          </div>
          <div className="box link">
            <h3>Solutions</h3>
            <ul className="w-highlight">
              <li>Accessibility Widget</li>
            </ul>

            <ul className="w-highlight">
              <h3 className="soon">Soon</h3>

              <li>Accessibility Scanner</li>
              <li>Contrast Checker</li>
            </ul>
            <br />

            <h3>User Plus</h3>
            <p className="copyright">
              Copyright ©2022 All rights reserved UserPlus.
            </p>
          </div>
          <div className="box">
            <h3>About</h3>
            <ul className="w-highlight">
              <li>Who We Are</li>
              <li>Team</li>
              <li>Brand</li>
              <li>Contact Us</li>
              <li>Support</li>
            </ul>
          </div>
        </div> */}
        <div id="Group_1231">
          <div id="Group_1230">
            <svg class="Path_207" viewBox="0 0 2079.812 1367.997">
              <path
                id="Path_207"
                d="M 0 0 L 2079.8115234375 0 L 2079.8115234375 1367.99658203125 L 0 1367.99658203125 L 0 0 Z"
              ></path>
            </svg>
            <div id="Group_1101">
              <img id="Group_1094_h" src={Footerlogo} alt="footer logo" />
            </div>
          </div>
          <div id="Group_1229">
            <div id="Group_1227">
              <svg class="Rectangle_305">
                <rect
                  id="Rectangle_305"
                  rx="0"
                  ry="0"
                  x="0"
                  y="0"
                  width="1088"
                  height="3"
                ></rect>
              </svg>
              <p id="were_working_hard_even_through">{t("fMSG")}</p>
              <div id="Group_1226">
                <Link to="#" id="Terms_of_Use_">
                  {t("TermsofUse")}
                  <br />
                </Link>
                <Link to="#" id="Accessibility_Statement">
                  {t("AccessibilityStatement")}
                </Link>
                <Link to="#" id="Font_License__________________">
                  {t("FontLicense")}
                </Link>
                <Link to="#" id="Cookie_Policy_">
                  {t("CookiePolicy")}
                  <br />
                </Link>
                <Link to="#" id="Privacy_Policy_">
                  {t("PrivacyPolicy")}
                  <br />
                </Link>
              </div>
            </div>
            <div id="Group_1228">
              <div id="Group_1220">
                <p id="Compliance_">
                  {t("Compliance")}
                  <br />
                </p>
                <Link to=" ">
                  <p id="ADA_WCAG_">
                    ADA
                    <br />
                    WCAG
                    <br />
                  </p>
                </Link>
              </div>
              <div id="Group_1222">
                <Link to=" ">
                  <p id="Industries_">
                    {t("Industries")}
                    <br />
                  </p>
                </Link>
                <p id="Powering_Accessibility_for_All">
                  Powering Accessibility for All Industries
                  <br />
                  Government & Public Sector
                  <br />
                  Banking, Financial, Insurance
                  <br />
                  Academia & Education
                  <br />
                  Consumer & Retail
                  <br />
                  IT & Security
                  <br />
                  Healthcare & Medical
                  <br />
                  Automotive & Transportation
                  <br />
                  NGO
                  <br />
                  Media & Entertainment
                  <br />
                  Law Enforcement
                </p>
              </div>
              <div id="Group_1223">
                <p id="Sources_">
                  {t("Sources")}
                  <br />
                </p>
                <p id="Who_We_Are_Team_Brand_Contact_">
                  <Link to=" ">{t("WhoWeAre")}</Link>
                  <br />
                  <Link to=" ">{t("Team")}</Link>
                  <br />
                  <Link to=" ">{t("Brand")}</Link>
                  <br /> <Link to=" ">{t("ContactUs")}</Link>
                  <br /> <Link to=" ">{t("Support")}</Link>
                </p>
              </div>
              <div id="Group_1224">
                <p id="UserPlus">UserPlus</p>
                <p id="Copyright__2022_-_2022_UserPlu">
                  Copyright © 2022 - 2022 UserPlus.,
                  <br />
                  {t("AllRightsReserved")}
                  <br />
                  <br />
                  <br />
                </p>
              </div>
              <div id="Group_1225">
                <p id="About_">
                  <Link to=" ">{t("About")}</Link>
                  <br />
                </p>
                <p id="Who_We_Are_Team_Brand_Contact__is">
                  <Link to=" ">{t("WhoWeAre")}</Link>
                  <br /> <Link to=" ">{t("Team")}</Link>
                  <br /> <Link to=" ">{t("Brand")}</Link>
                  <br /> <Link to=" ">{t("ContactUs")}</Link>
                  <br /> <Link to=" ">{t("Support")}</Link>
                </p>
              </div>
              <div id="Group_1221">
                <p id="Soon_">
                  {t("Soon")}
                  <br />
                </p>
                <p id="Section_5O8_EN_301-549_AODA_HI">
                  Section 5O8
                  <br />
                  EN 301-549
                  <br />
                  AODA
                  <br />
                  HIPAA
                  <br />
                  GDPR
                  <br />
                  COPPA
                  <br />
                  FERPA
                  <br />
                  ATAG
                  <br />
                  CVAA
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div className="legal">
        <p>Copyright ©2022 All rights reserved</p>
      </div>{" "} */}
    </>
  );
};

export default Footer;
