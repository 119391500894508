import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./FontSize.css";
import Typography from "@mui/material/Typography";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#000000",
  fontWeight: "bold",
}));
function FontSize() {
  const [fontSize, setFontSize] = useState(16);
  const [fontCount, setFontCount] = useState(1);
  const changeFontSize = (type) => {
    console.log(type);
    setFontSize(fontSize + 10);
    setFontCount(fontCount + 1);
    if (fontCount == 4) {
      setFontSize(16);
      setFontCount(1);
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }} style={{ fontSize: `${fontSize}px` }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{ fontSize: `${fontSize}px` }}
      >
        <Grid item xs={2} sm={4} md={4}>
          <Item>
            <button
              onClick={() => {
                changeFontSize("increase");
              }}
            >
              T+
            </button>
          </Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>
            <Typography variant="body1" style={{ fontSize: `${fontSize}px` }}>
              from 'react-router-dom'ewffewfwefwfwef
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Item>
            {" "}
            <Typography variant="body1" style={{ fontSize: `${fontSize}px` }}>
              from 'react-router-dom'ewffewfwefwfwef
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>{" "}
        <Grid item xs={2} sm={4} md={4}>
          <Item>xs=2</Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FontSize;
