import React from "react";
import logo from "../../assets/img/logo.svg";
import "./ComingSoon.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function ComingSoon() {
  return (
    <>
      <header className="App-header">
        <div>
          <div className="App-logo">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          {/* <div className="App-soon"></div>
          <div className="Counter">
            {" "}
            <Typography variant="h2" color="initial">
              Soon
            </Typography>
            {/* <p>Soon</p>
          </div> */}{" "}
        </div>
      </header>
    </>
  );
}

export default ComingSoon;
