import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import Group_1094 from "../../assets/img/Group_1094.png";
import "./Nav.css";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../lang/LanguageSwitcher";
const Nav = () => {
  const [click, setClick] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {/* <Head /> */}
      <header>
        {/* <nav className="flexSB navbar "> */}
        <nav>
          {/* <ul
            className={click ? "mobile-nav" : "flexSB start "}
            onClick={() => setClick(false)}
          >
            <img className="Group_1094_fs" src={Group_1094} alt="" />
            <li>
              <Link to="/pricing">{t("Pricing")}</Link>
            </li>
            <li>
              <Link to="/about">{t("About")}</Link>
            </li>
            <li>
              <Link to="/resources">{t("Resources")}</Link>
            </li>{" "}
          </ul>
          <ul>
            <span className="lineGreen">|</span>
            <li>
              <Link to="/signin">{t("SignIn")}</Link>
            </li>
        
            <li>
              <Link to="/getwidget" className="getwidget">
                {t("GetFreeWedget")}
              </Link>
            </li>
           
            <li>
              <LanguageSwitcher />
            </li>
           </ul>

          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button> */}{" "}
          <div id="Group_1145">
            <div id="Group_80">
              <svg className="Path_157">
                {/* <path
                  id="Path_157"
                  d="M 0 0 L 2038 0 L 2038 103.987190246582 L 0 103.987190246582 L 0 0 Z"
                ></path> */}
              </svg>
            </div>
            <div id="Group_1144">
              <div id="Group_1143">
                <div id="Group_1142">
                  <span id="Pricing">
                    <Link to="#" id="pricing">
                      {t("Pricing")}
                    </Link>
                  </span>
                  <span id="Resources">
                    <Link to="/resources" id="">
                      {t("Resources")}
                    </Link>
                  </span>
                  <span id="About">
                    <Link to="/about" id="">
                      {t("About")}
                    </Link>
                  </span>
                </div>
                <div id="Group_1141">
                  <div id="Component_11__1" className="Component_11___1">
                    <svg className="Rectangle_307">
                      <rect
                        id="Rectangle_307"
                        rx="22.5"
                        ry="22.5"
                        x="0"
                        y="0"
                        width="263"
                        height="45"
                      ></rect>
                    </svg>
                    <span id="Get_Free_Widget">{t("GetFreeWidget")}</span>
                  </div>
                  <span className="link" id="Sign_in">
                    {t("Signin")}
                  </span>
                </div>
                <svg className="Rectangle_321">
                  <rect
                    id="Rectangle_321"
                    rx="0"
                    ry="0"
                    x="0"
                    y="0"
                    width="3"
                    height="25"
                  ></rect>
                </svg>
              </div>
              <div id="Group_1104">
                <img
                  id="Group_1094_fs"
                  src={Group_1094}
                  // srcset="Group_1094_fs.png 1x, Group_1094_fs@2x.png 2x"
                  alt="logo"
                />

                {/* </svg> */}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Nav;
