import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import { Typography } from "@mui/material";
import TableOfContent from "./TableOfContent";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Cursor from "../Cursor/Cursor";
import CursorImg from "../../assets/img/cursor.svg";
import Cursorunderline from "../../assets/img/cursorunderline.png";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useTranslation } from "react-i18next";

function Widget({ show }) {
  const { t } = useTranslation();
  // Align
  const [alignment, setalignment] = useState("center");
  const [select, setSelect] = useState(0);
  const alignmentStates = ["left", "center", "right"];
  const [showTableOfContent, setShowTableOfContent] = useState(false);
  const [showCursor, setShowCursor] = useState(false);
  const alignmentIcon = [
    <FormatAlignLeftIcon />,
    <FormatAlignJustifyIcon />,
    <FormatAlignRightIcon />,
  ];

  const handleAlignment = (e) => {
    e.preventDefault();
    setalignment(alignmentStates[select]);
    setSelect(select + 1);
    if (select >= 2) {
      setSelect(0);
    }
  };

  //   Font Size
  const [fontSize, setFontSize] = useState(16);
  const [fontCount, setFontCount] = useState(1);
  const [textSizeIcon, setTextSizeIcon] = useState("small");
  //   const [headings, setHeadings] = useState("small");
  const textIcoOpt = ["small", "medium", "large"];
  const changeFontSize = () => {
    const elements = Array.from(document.querySelectorAll("p"));

    setFontSize(fontSize + 10);
    setFontCount(fontCount + 1);
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.fontSize = fontSize;
    }

    setTextSizeIcon(textIcoOpt[fontCount - 1]);
    if (fontCount == 4) {
      setFontSize(16);
      setFontCount(1);
    }
  };

  //   Link highlight
  const [highlightLink, setHighlightLink] = useState(false);
  const handleLinks = (e) => {
    setHighlightLink(!highlightLink);
    const elements = Array.from(
      document.querySelectorAll("a[href] , li > a, a> li, .w-highlight > li")
    );
    if (highlightLink) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.border = "2px solid yellow";
        elements[i].style.background = "#0c0c14";
        elements[i].style.padding = "10px";
        elements[i].style.textDecoration = "underLine";
      }
    } else {
      // not working undow
    }
  };

  return (
    <>
      {show == true && (
        <div>
          <nav
            style={{
              position: "fixed",
              top: "6em",
              left: "2em",
              padding: "2em",
              borderRadius: "20px",
              border: "2px #0c0c14 solid",
            }}
            className="Content"
          >
            <h1> {t("AccessibilityMenu")} </h1>
            <ul>
              <li>
                {/* Text alignItems */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={handleAlignment}
                      >
                        {alignmentIcon[select]}
                        <div className="display-container">
                          <div className="name">{t("AlignItem")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Font size */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => {
                          changeFontSize();
                        }}
                      >
                        <FormatSizeIcon fontSize={textSizeIcon} />
                        <div className="display-container">
                          <div className="name">{t("FontSize")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Table of content */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => {
                          setShowTableOfContent(!showTableOfContent);
                        }}
                      >
                        <AccountTreeIcon />
                        <div className="display-container">
                          <div className="name">{t("TableOfContent")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Cursor */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => {
                          setShowCursor(!showCursor);
                        }}
                      >
                        {showCursor ? (
                          <img
                            src={CursorImg}
                            alt=""
                            style={{ maxWidth: "24px" }}
                          />
                        ) : (
                          <img
                            src={Cursorunderline}
                            alt=""
                            style={{ maxWidth: "24px" }}
                          />
                        )}
                        <div className="display-container">
                          <div className="name">
                            {showCursor ? t("Cursor") : t("UnderLine")}
                          </div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Highlight */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={handleLinks}
                      >
                        <InsertLinkIcon />
                        <div className="display-container">
                          <div className="name">{t("HighlightLinks")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      )}
      <Typography style={{ textAlign: alignment, fontSize: `${fontSize}px` }}>
        EX just For Testability
      </Typography>
      <Typography
        variant="body1"
        style={{ fontSize: `${fontSize}px`, textAlign: alignment }}
      >
        EX just For Testability EX just For Testability EX just For Testability
        EX just For Testability EX just For Testability{" "}
      </Typography>
      <TableOfContent show={showTableOfContent} />
      {showCursor && <Cursor />}
    </>
  );
}

export default Widget;
