// import logo from './logo.svg';
import React, { useState } from "react";
import "./App.css";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import FontSize from "./components/FontSize/FontSize";
import { Route, Routes } from "react-router-dom";
// import DarkMode from "./util/DarkMode/Darkmode";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useLocalStorage from "use-local-storage";
import Home from "./components/Home/Home";
import Footer from "./util/Footer/Footer";
import Nav from "./util/Nav/Nav";
import Widget from "./components/Widget/Widget";
import TableOfContent from "./components/Widget/TableOfContent";
import Resources from "./components/Resources/Resources";

function App() {
  // const [darkMode, setDarkMode] = useState(false);

  // const theme = createTheme({
  //   palette: {
  //     type: darkMode ? "dark" : "light",
  //   },
  // });
  const [theme, setTheme] = useLocalStorage("theme" ? "dark" : "light");

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };
  return (
    <div className="App" theme={theme}>
      <Nav />

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/dark" element={<DarkMode />} /> */}
        <Route path="/font" element={<FontSize />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/widget" element={<Widget />} />
        <Route path="/resources" element={<Resources />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
