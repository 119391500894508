import React from "react";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import company from "../../assets/img/zui.png";
import Typography from "@mui/material/Typography";
import "./TrustedBy.css";
import { useTranslation } from "react-i18next";

function TrustedBy() {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <div>
      <Grid item md={12}>
        <Grid item xs={2} sm={4} md={10}>
          {" "}
          <Typography variant="h3" className="title">
            {t("TrustedBy")}
          </Typography>
          <Grid item xs={6} sm={8} md={8}>
            <div>
              <Grid item xs={12} sm={6} md={6}>
                <Slider {...settings}>
                  <div>
                    <img
                      src={company}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div>
                    <img
                      src={company}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div>
                    <img
                      src={company}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div>
                    <img
                      src={company}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div>
                    <img
                      src={company}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <br />
                </Slider>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default TrustedBy;
