import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import widgetIco from "../../assets/img/up.png";
import LanguageSwitcher from "../../util/lang/LanguageSwitcher";
import Cursor from "../Cursor/Cursor";
import TableOfContent from "../Widget/TableOfContent";

import "./Resources.css";
function Resources() {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();
  // Align
  const [alignment, setalignment] = useState("left");
  const [select, setSelect] = useState(0);
  const alignmentStates = ["left", "center", "right"];
  const [showTableOfContent, setShowTableOfContent] = useState(false);
  const [showCursor, setShowCursor] = useState(false);
  const alignmentIcon = [
    // <FormatAlignLeftIcon />,
    // <FormatAlignJustifyIcon />,
    // <FormatAlignRightIcon />,
  ];

  const handleAlignment = (e) => {
    e.preventDefault();
    setalignment(alignmentStates[select]);
    setSelect(select + 1);
    if (select >= 2) {
      setSelect(0);
    }
  };

  //   Font Size
  const [fontSize, setFontSize] = useState(16);
  const [fontCount, setFontCount] = useState(1);
  const [textSizeIcon, setTextSizeIcon] = useState("small");
  //   const [headings, setHeadings] = useState("small");
  const textIcoOpt = ["small", "medium", "large"];
  const changeFontSize = () => {
    const elements = Array.from(
      document.querySelectorAll("p , h1, h2 ,h3, span")
    );
    setFontSize(fontSize + 10);
    setFontCount(fontCount + 1);
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.fontSize = fontSize;
    }

    setTextSizeIcon(textIcoOpt[fontCount - 1]);
    if (fontCount == 4) {
      setFontSize(16);
      setFontCount(1);
    }
  };

  //   Link highlight
  const [highlightLink, setHighlightLink] = useState(false);
  const handleLinks = (e) => {
    setHighlightLink(!highlightLink);
    const elements = Array.from(
      document.querySelectorAll("a[href] , li > a, a> li, .w-highlight > li")
    );

    if (highlightLink) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add("highlightLink");
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("highlightLink");
      }
    }
  };

  //   line-height
  const [lineHeight, setLineHeight] = useState(1);
  const [lineHeightCounter, setLineHeightCounter] = useState(1);
  const handLineHeight = (e) => {
    // const elements = Array.from(
    //   document.querySelectorAll("p , h1, h2,h3,h4,h5,h6, span ,li,a ")
    // );
    e.preventDefault();
    console.log(lineHeight, "lineHeight");
    console.log(lineHeightCounter, "lineHeightCounter");
    setLineHeight(lineHeight + 1);
    setLineHeightCounter(lineHeightCounter + 1);
    if (lineHeightCounter >= 3) {
      setLineHeight(1);
      setLineHeightCounter(1);
    }
  };

  return (
    <div>
      <div id="Component_24__3" className="Component_24___3">
        {/* <button
          type="button"
          onClick={handleOpen}
          style={{ backgroundColor: "#fff" }}
        >
          <img id="Group_1098_hv" src={widgetIco} alt="Accessibility Menu" />
        </button> */}
      </div>
      {show === true && (
        <div>
          <nav
            style={{
              position: "fixed",
              bottom: "9em",
              right: "0em",
              padding: "2em",
              borderRadius: "20px",
              border: "2px #0c0c14 solid",
              zIndex: 1,
            }}
            className="Content"
          >
            <h1> {t("AccessibilityMenu")} </h1>
            <ul>
              <li>
                {/* Text alignItems */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        className="btn"
                        variant="contained"
                        component="span"
                        onClick={handleAlignment}
                      >
                        {alignmentIcon[select]}
                        <div className="display-container">
                          <div className="name">{t("AlignItem")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Font size */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        className="btn"
                        variant="contained"
                        component="span"
                        onClick={() => {
                          changeFontSize();
                        }}
                      >
                        {/* <FormatSizeIcon fontSize={textSizeIcon} /> */}
                        <div className="display-container">
                          <div className="name">{t("FontSize")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Table of content */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        className="btn"
                        variant="contained"
                        component="span"
                        onClick={() => {
                          setShowTableOfContent(!showTableOfContent);
                        }}
                      >
                        {/* <AccountTreeIcon /> */}
                        <div className="display-container">
                          <div className="name">{t("TableOfContent")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Cursor */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        className="btn"
                        variant="contained"
                        component="span"
                        onClick={() => {
                          setShowCursor(!showCursor);
                        }}
                      >
                        {showCursor ? (
                          <img
                            // src={CursorImg}
                            alt=""
                            style={{ maxWidth: "24px" }}
                          />
                        ) : (
                          <img
                            // src={Cursorunderline}
                            alt=""
                            style={{ maxWidth: "24px" }}
                          />
                        )}
                        <div className="display-container">
                          <div className="name">
                            {showCursor ? t("Cursor") : t("UnderLine")}
                          </div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <li>
                {/* Highlight */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        className="btn"
                        variant="contained"
                        component="span"
                        onClick={handleLinks}
                      >
                        {/* <InsertLinkIcon /> */}
                        <div className="display-container">
                          <div className="name">{t("HighlightLinks")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <br />
              <br />
              <li>
                {/* line High */}
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <Button
                        className="btn"
                        variant="contained"
                        component="span"
                        onClick={handLineHeight}
                      >
                        {/* <InsertLinkIcon /> */}
                        <div className="display-container">
                          <div className="name">{t("lineHeight")}</div>
                        </div>
                      </Button>
                    </label>
                  </Stack>
                </div>
              </li>
              <li>
                <div className="item">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="">
                      <LanguageSwitcher />
                    </label>
                  </Stack>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {/* <TableOfContent show={showTableOfContent} /> */}

      {showCursor && <Cursor />}
    </div>
  );
}

export default Resources;
