import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./Home.css";
import Typography from "@mui/material/Typography";
import logo from "../../assets/img/bg.svg";
import WhyUs from "../WhyUs/WhyUs";
import TrustedBy from "../TrustedBy/TrustedBy";
import { Button, IconButton } from "@mui/material";
import Info from "../Info/Info";
import { useTranslation } from "react-i18next";
import Widget from "../Widget/Widget";
import ButtonIcon from "../../assets/img/up.png";

function Home() {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <main className="content">
      {/* <Grid item> */}

      {/* <Button
        // className="Widget-btn"
        variant="outlined"
        // color="primary"
        aria-label="Open "
        style={{
          position: "fixed",
          top: "8em",
          left: "5em",
          maxidth: "100px",
          borderRadius: "20%",
          border: "1px solid rgb(69, 128, 255)",
        }}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <img
          src={ButtonIcon}
          alt="A11y Menu"
          style={{ maxWidth: "100%", with: "100px" }}
        />
      </Button> */}
      {/* </Grid> */}
      <Box sx={{ flexGrow: 1 }} style={{ display: "block" }} className="home">
        <img src={logo} alt="" className="bg" />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item md={12} className="bg">
            <Grid
              item
              xs={2}
              sm={4}
              md={12}
              className="center"
              style={{ color: `#fff` }}
            >
              {/* <Item> */}
              <Grid item xs={6} sm={8} md={12}>
                <Typography variant="body1">
                  {t("Header_SubTitle")}
                  <br />
                  {t("Header_SubTitle_part2")}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8} md={12}>
                <Typography variant="h2">
                  {t("Header_Title")}
                  <br />
                  {t("Header_Title_part2")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={6} sm={8} md={12}>
        <WhyUs />
      </Grid>
      <Grid item xs={6} sm={8} md={12}>
        <TrustedBy />
      </Grid>
      <Grid item xs={6} sm={8} md={12} className="getFreeWidget">
        <Button>{t("GetFreeWedget")}</Button>
      </Grid>
      <br />
      <Grid item xs={6} sm={8} md={10}>
        <Info />
      </Grid>
      {/* <Grid item xs={6} sm={8} md={12} className="getFreeWidget">
        <Button>{t("GetFreeWedget")}</Button>
      </Grid> */}
      {/* <Widget show={showMenu} /> */}
      {/* <Grid item xs={6} sm={8} md={12} className="getFreeWidget">
        <Button>{t("GetFreeWedget")}</Button>
      </Grid> */}
    </main>
  );
}

export default Home;
