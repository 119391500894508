import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CheckIcon from "@mui/icons-material/Check";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
const theme = createTheme();

function WhyUs() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        {/* Hero unit */}

        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <CardContent sx={{ flexGrow: 1 }}>
                <CheckIcon color="success" />
                <Typography gutterBottom variant="h5" component="h2">
                  {t("Service_Compatibility")}
                </Typography>
                <Typography>{t("Service_CompatibilityBody")}</Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardContent sx={{ flexGrow: 1 }}>
                <CheckIcon color="success" />
                <Typography gutterBottom variant="h5" component="h2">
                  {t("Service_CommunityService")}{" "}
                </Typography>
                <Typography>{t("Service_CommunityServiceBody")}</Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardContent sx={{ flexGrow: 1 }}>
                <CheckIcon color="success" />
                <Typography gutterBottom variant="h5" component="h2">
                  {t("Service_AccessibleWebsite")}
                </Typography>
                <Typography>{t("Service_AccessibleWebsiteBody")}</Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}

export default WhyUs;
