import React from "react";
import "./TableOfContent.css";
function useHeadings() {
  const [headings, setHeadings] = React.useState([]);
  React.useEffect(() => {
    const elements = Array.from(
      document.querySelectorAll("h2, h3, h4, h5, h6")
    ).map((element) => ({
      id: element.id,
      text: element.textContent ?? "",
      level: Number(element.tagName.substring(1)),
    }));

    setHeadings(elements);
  }, []);
  return headings;
}
export function useScrollSpy(ids, options) {
  const [activeId, setActiveId] = React.useState();
  const observer = React.useRef();
  React.useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id));
    observer.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    }, options);
    elements.forEach((el) => {
      if (el) {
        observer.current?.observe(el);
      }
    });
    return () => observer.current?.disconnect();
  }, [ids, options]);
  return activeId;
}

function TableOfContent({ show }) {
  const headings = useHeadings();
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    { rootMargin: "0% 0% -25% 0%" }
  );
  return (
    <>
      {show == true && (
        <nav
          style={{ position: "fixed", top: "1em", left: "1em" }}
          className="Content"
        >
          <h1>Table content</h1>
          <ul>
            {headings.map((heading) => (
              <li key={heading.id} style={{ marginLeft: `${heading.level}em` }}>
                <a
                  href={`#${heading.id}`}
                  style={{
                    fontWeight: activeId === heading.id ? "bold" : "normal",
                  }}
                >
                  {heading.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  );
}

export default TableOfContent;
