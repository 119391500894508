import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { CardContent, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import { useTranslation } from "react-i18next";

function Info() {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "80%", justifyContent: "center", padding: "20px" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={7}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="h2">
              <ArrowForwardIosIcon color="success" />{" "}
              {t("TheBasicsofWebAccessibility")}
            </Typography>
            <Typography
              sx={{ width: "90%", justifyContent: "center", padding: "20px" }}
            >
              {t("TheBasicsofWebAccessibilityBody")}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={4}>
          <CardContent sx={{ flexGrow: 1 }}>
            {/* <CheckIcon color="success" /> */}
            <AccessibilityNewIcon color="primary" sx={{ fontSize: 300 }} />
          </CardContent>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Info;
